import React from "react";

const HistoryPart = ({ part }) => {
  if (part !== undefined && part.length > 0) {
    return (
      <>
        <br />
        <div className="container bg-light card-header nopol">
          Daftar Pergantian Suku Cadang
        </div>
        <table className="table table-secondary table-striped">
          <thead>
            <th width="20">No.</th>
            <th>&nbsp;&nbsp;Nama</th>
            <th>Unit</th>
            <th width="120" align="center">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Garansi sd.
            </th>
          </thead>
          <tbody>
            {part.map((rec, i) => {
              return (
                <tr key={i} width="100%">
                  <td width="20">{i + 1}.</td>
                  <td>{rec.item}</td>
                  <td>{rec.unit}</td>
                  <td align="center">{rec.garansi}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  } else {
    return <></>;
  }
};

export default HistoryPart;
