import React from "react";
import HistoryCard from "./HistoryCard";

const HistoryList = ({ rs }) => {
  return (
    <>
      {rs.map((dt, k) => {
        return (
          <div key={k + 1} className="container">
            <HistoryCard
              key={k}
              idx={k}
              header={dt.header}
              note={dt.note}
              km={dt.km}
              part={dt.part}
              shop={dt.shop}
              memo={dt.memo}
            />
          </div>
        );
      })}
    </>
  );
};
export default HistoryList;
