import React, { Component } from "react";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
const API_URL = 'https://record.suryamotor.co.id/fecth/';

class Booking  extends Component{
    constructor(props){
        super(props);
        this.state = {result: {}, isLoading: true, bTampil: true}
       
    }

    componentWillMount(){
      this.setState({isLoading: true});
    }
    render(){
        return(<div>Halaman Booking</div>);
    }
}
export default Booking;