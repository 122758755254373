import React, { Component } from "react";
import Navbar from "./components/inc/Navbar";
import Vehicle from "./components/pages/Kendaraan";
import Slider from "./components/inc/Slider";
import History from "./components/pages/RiwayatScroll";
import Booking from "./components/pages/Booking";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import "./App.css";
class App extends Component {
  render() {
    return (
      <div className="d-flex flex-column min-vh-4 justify-content-center align-items-center">
        <Navbar />
        <Slider />
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={[<Vehicle />, <History />]} />
            <Route path="booking/*" element={<Booking />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
