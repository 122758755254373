import React, { Component } from "react";
import axios from "axios";
import OnService from "./OnService";
const API_URL = "https://record.suryamotor.co.id/fecth/";
class Kendaraan extends Component {
  constructor(props) {
    super(props);
    this.state = { result: {}, isLoading: true, bTampil: false };
  }
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("sess_id");
    const user = {
      sess_id: id,
      code: "01000000",
    };
    axios
      .post(API_URL, user, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ result: data });
        this.setState({ isLoading: false });
        this.setState({ bTampil: data.onProgress });
      });
  }
  render() {
    let strNopol = (
      <div className="d-flex align-items-center">
        <strong>Memuat...</strong>
        <div
          className="spinner-border ms-auto"
          role="status"
          aria-hidden="true"
        ></div>
      </div>
    );
    let strMerk = (
      <div className="spinner-grow text-info" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
    let strJenis = (
      <div className="spinner-grow text-info" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
    let strTahun = (
      <div className="spinner-grow text-info" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
    let strLast = (
      <div className="spinner-grow text-info" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
    let strPemilik = strLast;
    let strVCard = strLast;
    let strCB = strLast;
    let bRes = true;
    if (!this.state.isLoading) {
      strNopol = this.state.result.nopol;
      strJenis = this.state.result.jenis;
      strMerk = this.state.result.merk;
      strTahun = this.state.result.tahun;
      strLast = this.state.result.last;
      strPemilik = this.state.result.pemilik;
      strVCard = this.state.result.vcard;
      strCB = this.state.result.cashback;
      bRes = this.state.result.res;
    }
    if (!bRes) {
      window.location.href = "https://www.selismolis.com/";
    } else {
      return (
        <div className="container">
          <div className="container">
            <p>&nbsp;</p>
            <div className="card border-danger text-bg-light mb-3">
              <div className="card-header nopol">{strNopol}</div>
              <div className="card-body">
                <table className="table table-light table-striped">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td width="130">Nama Pemilik</td>
                      <td width="2">:</td>
                      <td>{strPemilik}</td>
                    </tr>
                    <tr>
                      <td width="130">No Pelanggan</td>
                      <td width="2">:</td>
                      <td>{strVCard}</td>
                    </tr>
                    <tr>
                      <td width="130">Saldo Cashback</td>
                      <td width="2">:</td>
                      <td>{strCB}</td>
                    </tr>
                    <tr>
                      <td width="130">Jenis</td>
                      <td width="2">:</td>
                      <td>{strJenis}</td>
                    </tr>
                    <tr>
                      <td>Model</td>
                      <td>:</td>
                      <td>{strMerk}</td>
                    </tr>
                    <tr>
                      <td>Tahun</td>
                      <td>:</td>
                      <td>{strTahun}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {this.state.bTampil ? <OnService /> : ""}
        </div>
      );
    }
  }
}
export default Kendaraan;
